import React, { useEffect } from 'react';
import {Redirect } from 'react-router-dom';
import { logged } from '../../auth/helpers';
import { useDispatch } from 'react-redux';
import SignInForm  from '../../components/auth/SignInForm'
import { resetQuotationProductQuotation } from '../../services/redux/quotationRedux';

const Signin = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		document.getElementById('body').classList.add('loginContainer');
		dispatch({
			type: 'CLEAR_REDUX',
		});
	}, []);

	return (
		<>
			{logged() ? <Redirect to="/" /> : null}
			<main className="form-signin">
				<div>
					<div className="signIn">
						<img className="logInLogoUno" src="/assets/img/logo_vertical_new.png" alt=""/>
						<div className="col-xl-4 col-lg-6 col-md-6 col-12 mx-auto singin-form">
							<SignInForm/>
						</div>
						<img className="logInLogoDos" src="/assets/img/logo_viacottone_new.png" alt="Logo Viacotone"/>
					</div>
				</div>
			</main>
		</>
	)
}

export default Signin;
