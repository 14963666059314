import axios from 'axios';
import { API_URL } from '../config';
import { createBrowserHistory } from 'history';
import qs from 'qs';
import { getToken, signout } from '../../auth/helpers';

const history = createBrowserHistory();

const formatAxiosError = (message) => {
	if (Array.isArray(message)) {
		return (
			<ul className='text-start'>
				{message.map(error => (
					<li>{error}</li>
				))}
			</ul>
		);
	} else {
		return message;
	}
}

function httpApiGet(url, data) {
	return (
		axios.get(API_URL + '/' + url, {
			params: data ? data : null,
			headers: { Authorization: `Bearer ${getToken()}` },
			paramsSerializer: function (data) {
				return qs.stringify(data, { arrayFormat: 'repeat' })
			}
		}).then(response => {
			if (response.status === 200) {
				if (response.data.data) {
					return response.data.data;
				}
				return response.data;
			}
		})
			.catch((request => {
				console.log(request.response)
				if(!request.response) {
					throw new ApiError("Servidor no disponible")
				}

				if (request.response.status === 401) {
					signout(() => {
						history.push('/');
						window.location.reload();
					});
					return null;
				}
				if (request.response) {
					throw new ApiError(request.response.data.message, request.response.status);
				}
			}))
	);
}

function httpApiGetFile(url, data) {

	return (
		axios.get(API_URL + '/' + url, {
			params: data ? data : null,
			headers: {
				Authorization: `Bearer ${getToken()}`,
			},
			responseType: "blob"
		}).then(response => {
			if (response.status === 200) {
				if (response.data.data) {
					return response.data.data;
				}
				return response.data;
			}
		})
			.catch((request => {
				console.log(request.response)
				if(!request.response) {
					throw new ApiError("Servidor no disponible")
				}
				if (request.response.status === 401) {
					signout(() => {
						history.push('/');
						window.location.reload();
					});
					return null;
				}
				if (request.response) {
					throw new ApiError(request.response.data.message, request.response.status);
				}
			}))
	);
}

function httpApiGetPaginated(url, data) {

	return (
		axios.get(API_URL + '/' + url, {
			params: data ? data : null,
			headers: { Authorization: `Bearer ${getToken()}` },
			paramsSerializer: function (data) {
				return qs.stringify(data, { arrayFormat: 'repeat' })
			}
		}).then(response => {
			if (response.status === 200) {
				return response.data;
			}
		})
			.catch((request => {
				console.log(request.response)
				if(!request.response) {
					throw new ApiError("Servidor no disponible")
				}
				if (request.response.status === 401) {
					signout(() => {
						history.push('/');
						window.location.reload();
					});
					return null;
				}
				if (request.response) {
					throw new ApiError(request.response.data.message, request.response.status);
				}
			}))
	);
}

function httpApiPost(url, data = {}) {
	return (
		axios.post(API_URL + '/' + url, data, {
			headers: { Authorization: `Bearer ${getToken()}` }
		}).then(response => {
			return response.data;
		})
			.catch((request => {
				console.log(request.response)
				if(!request.response) {
					throw new ApiError("Servidor no disponible")
				}
				if (request.response.status === 401) {
					signout(() => {
						history.push('/');
						window.location.reload();
					});
					return null;
				}
				if (request.response) {
					throw new ApiError(request.response.data.message, request.response.status);
				}
			}))
	);
}

function httpApiPut(url, data = {}) {
	return (
		axios.put(API_URL + '/' + url, data, {
			headers: { Authorization: `Bearer ${getToken()}` }
		}).then(response => {
			return response.data;
		})
			.catch((request => {
				console.log(request.response)
				if(!request.response) {
					throw new ApiError("Servidor no disponible")
				}
				if (request.response.status === 401) {
					signout(() => {
						history.push('/');
						window.location.reload();
					});
					return null;
				}
				if (request.response) {
					throw new ApiError(request.response.data.message, request.response.status);
				}
			}))
	);
}

function httpApiPatch(url, data = {}) {
	return (
		axios.patch(API_URL + '/' + url, data, {
			headers: { Authorization: `Bearer ${getToken()}` }
		}).then(response => {
			return response.data;
		})
			.catch((request => {
				console.log(request.response)
				if(!request.response) {
					throw new ApiError("Servidor no disponible")
				}
				if (request.response.status === 401) {
					signout(() => {
						history.push('/');
						window.location.reload();
					});
					return null;
				}
				if (request.response) {
					throw new ApiError(request.response.data.message, request.response.status);
				}
			}))
	);
}

function httpApiUploadFile(url, file) {

	return (
		axios.post(API_URL + '/' + url, file, {
			headers: {
				Authorization: `Bearer ${getToken()}`,
				'Content-Type': 'multipart/form-data'
			}
		}).then(response => {
			if (response.status === 200 || response.status === 201) {
				return response.data;
			}
		})
			.catch((request => {
				console.log(request.response)
				if(!request.response) {
					throw new ApiError("Servidor no disponible")
				}
				if (request.response.status === 401) {
					signout(() => {
						history.push('/');
						window.location.reload();
					});
					return null;
				}
				if (request.response) {
					throw new ApiError(request.response.data.message, request.response.status);
				}
			}))
	);
}

function httpApiDelete(url, data) {
	return (
		axios.delete(API_URL + '/' + url, {
			data,
			headers: { Authorization: `Bearer ${getToken()}` }
		}).then(response => {
			if (response.status === 200) {
				return response.data;
			}
		})
			.catch((request => {
				console.log(request.response)
				if(!request.response) {
					throw new ApiError("Servidor no disponible")
				}
				if (request.response.status === 401) {
					signout(() => {
						history.push('/');
						window.location.reload();
					});
					return null;
				}
				if (request.response) {
					throw new ApiError(request.response.data.message, request.response.status);
				}
			}))
	);
}

class ApiError extends Error {
	constructor(message, status) {
		super(message);
		this.status = status;
	}
}

export {
	formatAxiosError,
	httpApiGet,
	httpApiGetPaginated,
	httpApiPost,
	httpApiPatch,
	httpApiDelete,
	httpApiUploadFile,
	httpApiGetFile,
	httpApiPut,
}