import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import ForgotForm from '../../components/auth/ForgotForm';

const Forgot = () => {
	useEffect(() => {
		document.getElementById('body').classList.add('loginContainer');
	}, []);

	return (
		<main className="form-signin">
			<div>
				<div className="container signIn">
					<img className="logInLogoUno" src="/assets/img/logo_vertical_new.png" alt=""/>
					<div className="col-xl-4 col-lg-6 col-md-6 col-12 mx-auto singin-form">
						<ForgotForm/>
					</div>
					<img className="logInLogoDos" src="/assets/img/logo_viacottone_new.png" alt="Logo Viacotone"/>
				</div>
			</div>
		</main>
	)
}

export default Forgot;
