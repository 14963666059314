import React, { useEffect } from 'react';
import ResetForm from '../../components/auth/ResetForm';

const ActivateAcount = ({ match }) => {

	useEffect(() => {
		document.getElementById('body').classList.add('loginContainer');
	}, [])

	return (
		<main className="form-signin">
			<div>
				<div className="signIn">
					<img className="logInLogoUno" src="/assets/img/logo_vertical_new.png" alt=""/>
					<div className="col-xl-4 col-lg-6 col-md-6 col-12 mx-auto singin-form">
						<ResetForm
							token={match.params.token}
							url={`${process.env.REACT_APP_API}/auth/activate-account`}
							buttonMessage="Activar cuenta"
						/>
					</div>
					<img className="logInLogoDos" src="/assets/img/logo_viacottone.svg" width="100%" alt="Logo Viacotone"/>
				</div>
			</div>
		</main>
	)
}

export default ActivateAcount;
