import { lazy } from "react";
import { QUOTATION_STATES } from "../constants/quotationStates";

export const DASHBOARD_ROUTE = {
	HOME: "/",
	QUOTATIONS_NEW: "nueva-cotizacion",
	QUOTATIONS_OPEN: "np-abiertas",
	QUOTATIONS_APPROVED: "np-aprobadas",
	QUOTATIONS_DELETED: "np-eliminadas",
	AUTHORIZATION_REQUEST: "solicitudes-autorizacion",
	STATISTICS: "estadisticas",
	CATEGORIES: "categorias",
	CLIENTS: "clientes",
	USERS: "usuarios",
	RECHAZOS: "rechazos",
	CLIENTS_PORTAL: "portal-cliente",
	REFERENTS: "referents",
};

export const ROUTES = [
	{
		path: DASHBOARD_ROUTE.HOME,
		exact: true,
		component: lazy(() => import("../screens/home/Home")),
	},
	{
		path: DASHBOARD_ROUTE.QUOTATIONS_NEW,
		exact: true,
		component: lazy(() => import("../screens/quotations/NewQuotation")),
	},
	{
		path: DASHBOARD_ROUTE.QUOTATIONS_OPEN,
		exact: true,
		component: lazy(() => import("../screens/quotations/Quotations")),
		props: { status: QUOTATION_STATES.OPEN }
	},
	{
		path: DASHBOARD_ROUTE.QUOTATIONS_APPROVED,
		exact: true,
		component: lazy(() => import("../screens/quotations/Quotations")),
		props: { status: QUOTATION_STATES.APPROVED }
	},
	{
		path: DASHBOARD_ROUTE.QUOTATIONS_DELETED,
		exact: true,
		component: lazy(() => import("../screens/quotations/Quotations")),
		props: { status: QUOTATION_STATES.DELETED }
	},
	{
		path: DASHBOARD_ROUTE.AUTHORIZATION_REQUEST,
		exact: true,
		component: lazy(() => import("../screens/quotations/Quotations")),
		props: { status: QUOTATION_STATES.AUTHORIZATION_REQUEST }
	},
	{
		path: DASHBOARD_ROUTE.STATISTICS,
		exact: true,
		component: lazy(() => import("../screens/statistics/Statistics")),
	},
	{
		path: DASHBOARD_ROUTE.CATEGORIES,
		exact: true,
		component: lazy(() => import("../screens/internalCategories/InternalCategories")),
	},
	{
		path: DASHBOARD_ROUTE.CLIENTS,
		exact: true,
		component: lazy(() => import("../screens/clients/Clients")),
	},
	{
		path: DASHBOARD_ROUTE.USERS,
		exact: true,
		component: lazy(() => import("../screens/users/Users")),
	},
	{
		path: DASHBOARD_ROUTE.RECHAZOS,
		exact: true,
		component: lazy(() => import("../screens/rejectionsReason/RejectionReason")),
	},
	{
		path: DASHBOARD_ROUTE.CLIENTS_PORTAL,
		exact: true,
		component: lazy(() => import("../screens/clientPortal/ClientPortal")),
	},
	{
		path: DASHBOARD_ROUTE.REFERENTS,
		exact: true,
		component: lazy(() => import("../screens/referents/Referents")),
	},
];