import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { authenticate, logged } from '../../auth/helpers';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { DASHBOARD_ROUTE } from '../../routes/dashboardRoutes';
import { formatAxiosError } from '../../services/helpers/axiosPrivate';
import { getAppDataAction } from '../../services/redux/appDataRedux';
import { getProfileAction } from '../../services/redux/profileRedux';

const LoginWithToken = ({ history }) => {
	const dispatch = useDispatch();

	const useQuery = () => {
		const { search } = useLocation();
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	const query = useQuery();
	const token = query.get('token');

	useEffect(() => {
		document.getElementById('body').classList.add('loginContainer');
		dispatch({
			type: 'CLEAR_REDUX',
		});
	}, []);

	useEffect(() => {
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_API}/auth/login-with-token`,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		})
			.then(response => {
				authenticate(response, () => {
					if (logged()) {
						redirectHome();
					}
				});

			})
			.catch(error => {
				if (error.response.status === 401) {
					history.push(DASHBOARD_ROUTE.SIGNIN);
					toast.error(formatAxiosError("No autorizado"));
					return;
				}

				toast.error(formatAxiosError(error.response.data.message));
			})
	}, [token])

	const redirectHome = () => {
		// dispatch(getAppDataAction()); [TODO] REVISAR
		dispatch(getProfileAction());
		history.push(DASHBOARD_ROUTE.HOME);
	}

	return (
		<>
			<ToastContainer
				position="bottom-right"
			/>

			{logged() ? <Redirect to="/" /> : null}

			<main className="form-signin">
				<div>
					<div className="signIn">
						<img className="logInLogoUno" src="/assets/img/logo_vertical_new.png" alt="" />
						<div className="col-xl-4 col-lg-6 col-md-6 col-12 mx-auto "></div>
					</div>
					<img className="logInLogoDos" src="/assets/img/logo_viacottone.svg" width="100%" alt="Logo Viacotone" />
				</div>

			</main>
		</>
	)
}

export default LoginWithToken;
